@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	/* font-family: 'Montserrat', sans-serif; */
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
	/* background: #26262B; */
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.landing-main {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
}

.landing-container {
  height: 85vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing-section-1 {
  height: 85%;
  width: 50%;
  font-size: 1.15rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background: white;
  border-radius: 5px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.landing-section-1 p {
  text-align: start;
  line-height: 200%;
  margin: 30px;
  background: white;
}

.landing-logo {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.landing-logo:hover {
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.75);
}

.resume-button {
  width: 125px;
  height: 50px;
  outline: none;
  border: none;
  cursor: pointer;
  color: white;
  background: #2BAAE2;
  border-radius: 5px;
  font-size: medium;
}

.resume-button:hover {
  background: white;
  color: black;
  border: 1px solid black;
}

@media (max-width: 875px) {
  .landing-logo {
    width: 100px;
    height: 100px;
  }
  .landing-section-1 {
    font-size: 1rem;
    width: 75%;
  }
  .landing-section-1 p {
    line-height: 150%;
  }
}
/*# sourceMappingURL=Landing.css.map */
.footer-main {
  height: 10vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-container {
  width: 50%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.footer-img-1 {
  width: 40px;
}

.footer-img-2 {
  width: 35px;
}
/*# sourceMappingURL=Footer.css.map */
.projects-main {
  width: 100vw;
  height: 85vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  font-family: 'Montserrat', sans-serif;
  overflow: scroll;
}

.switch-container, .trakd-container, .asana-clone-container {
  background: white;
  width: 350px;
  height: 350px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin: 10px 50px 30px 50px;
}

.switch-container button, .trakd-container button, .asana-clone-container button {
  width: 100px;
  height: 40px;
  background: #2BAAE2;
  color: white;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  border: none;
  letter-spacing: .05rem;
  font-size: .8rem;
}

.switch-container button:hover, .trakd-container button:hover, .asana-clone-container button:hover {
  border: 1px solid black;
  background: white;
  color: black;
}

.switch-carousel, .trakd-carousel, .asana-clone-carousel {
  max-width: 90%;
}

.switch img {
  width: 200px;
}
/*# sourceMappingURL=Projects.css.map */
.contact-main {
  height: 85vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
}

.contact-container {
  height: 75%;
  width: 50%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.social-links {
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.social-links div {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.social-links h1 {
  font-size: 2rem;
}

.social-links a {
  border-bottom: 2px solid #2BAAE2;
  text-decoration: none;
  color: black;
}

.social-links a h2 {
  font-size: medium;
}

.social-links a:hover {
  border-bottom: 2px solid red;
}

.contact-email {
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.contact-email h1 {
  font-size: 2rem;
}

.contact-email button {
  width: 150px;
  height: 60px;
  outline: none;
  border: none;
  cursor: pointer;
  color: white;
  background: #2BAAE2;
  border-radius: 5px;
  font-size: medium;
}

.contact-email button:hover {
  background: white;
  color: black;
  border: 1px solid black;
}
/*# sourceMappingURL=Contact.css.map */
.project-main {
  font-family: 'Montserrat', sans-serif;
  height: 95vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-main h1 {
  font-size: 1.5rem;
  font-weight: 600;
  text-decoration: underline;
}

.project-container {
  height: 80%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.project-container .project-middle-container {
  width: 80%;
  display: flex;
  justify-content: space-evenly;
}

.project-description {
  width: 50%;
  max-height: 100%;
  padding: 10px;
  text-align: left;
  line-height: 150%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  font-size: 1.05rem;
  margin-left: 10px;
}

#project-images {
  width: 50%;
  margin-right: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.post-buttons {
  width: 60%;
  height: 70px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.post-buttons button {
  width: 100px;
  height: 40px;
  outline: none;
  border: none;
  cursor: pointer;
  color: white;
  background: #2BAAE2;
  border-radius: 5px;
  font-size: medium;
}

.post-buttons button:hover {
  background: white;
  color: black;
  border: 1px solid black;
}

#disabled {
  background: #dddddd;
}

@media (max-width: 1200px) {
  .project-middle-container {
    flex-wrap: wrap;
  }
}
/*# sourceMappingURL=Project.css.map */
.nav-container {
  font-family: 'Montserrat', sans-serif;
  height: 5vh;
  width: 100vw;
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-container h1 {
  margin-left: 9vw;
  font-size: large;
}

.nav-container h1 .first_name {
  border-bottom: 2px solid red;
}

.nav-container h1 .last_name {
  border-bottom: 2px solid #2BAAE2;
}

.nav-container section {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  width: 50%;
}

.nav-container section span {
  height: 30px;
  width: 80px;
  font-size: 1.05rem;
  border: none;
  background-color: white;
  color: black;
  cursor: pointer;
  outline: none;
}

#about-selected {
  border-bottom: 2px solid red;
}

#projects-selected {
  border-bottom: 2px solid red;
}

#contact-selected {
  border-bottom: 2px solid red;
}

.nav-container section span:hover {
  border-bottom: 2px solid red;
}

@media (max-width: 875px) {
  .nav-container section {
    width: 60%;
  }
}
/*# sourceMappingURL=Nav.css.map */
